import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    let tempIndex = -1

    console.log(props)

    const [shippingTypes, setShippingTypes] = useState([
        { value: 1, label: 'Dealer Address' },
        { value: 2, label: 'Spiffee Address' },
        { value: 3, label: 'Subagent Address' },
    ])

    //these values dont matter for visas bc i did a bandaid fix to determine what the values is for visa via a function when sending the data to the backend submitReport()
    const [spiffTypes, setSpiffTypes] = useState([
        { value: 'Visa', label: '$100 Visa' },
        { value: 'Visa', label: '$50 Visa' },
        { value: 'Visa', label: '$25 Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Check', label: 'Check' },
    ])

    const [spiffeeList, setSpiffeeList] = useState([])
    const [spiffeePrespiffList, setSpiffeePrespiffList] = useState([])
    
    const [newSpiffeeData, setNewSpiffeeData] = useState([
        '', //name
        false, //if prespiff
        0, //amount
        '', //address
        '', //ATTN
        '', //spiff type
    ])
    const [newSpiffeeID, setNewSpiffeeID] = useState([
        '',
        false,
        0,
        '',
        '',
        '',
    ])
    
    const onChange = (e, index) => {
        console.log(e, index)
        if (index === 0)
        {
            onChange({value: 2, label: 'Spiffee Address'}, 3)
            onChange({value: 'Check', label: 'Check'}, 5)
        }
        if (index === 5)
        {   
            if (e.label === '$25 Visa') {onChange('25', 2)}
            if (e.label === '$50 Visa') {onChange('50', 2)}
            if (e.label === '$100 Visa') {onChange('100', 2)}
        }
        setNewSpiffeeData((newSpiffeeData) => newSpiffeeData.map((dataPoint, indexParent) => {
            if (index === 0 && indexParent === 1)
            {
                spiffeeList.map((spiffee, index2) => {
                    if (spiffee.value === e.value)
                    {
                        tempIndex = index2;
                        return spiffeePrespiffList[index2]
                    }
                })
                return tempIndex === -1 ? false:spiffeePrespiffList[tempIndex]
            }
            if (index === indexParent)
            {
                if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
            } else {return dataPoint}
        }))
        setNewSpiffeeID((newSpiffeeID) => newSpiffeeID.map((dataPoint, indexParent) => {
            if (index === 0 && indexParent === 1)
            {
                spiffeeList.map((spiffee, index2) => {
                    if (spiffee.value === e.value)
                    {
                        return spiffeePrespiffList[index2]
                    }
                })
            }
            if (index === indexParent)
            {
                if (e.value === undefined)
                    {
                        return e;
                    }
                    return e.value;
            } else {return dataPoint}
        }))
    }

    const clearReport = () => {
        console.log('clearing report!')
        setNewSpiffeeData([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
        setNewSpiffeeID([
            '',
            false,
            0,
            '',
            '',
            '',
            '',
        ])
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        let tempSpiffeeList = [];
        let tempSpiffeePrespiffList = []

        props.tokenSafeAPIRequest('/spiffee/dealership/' + props.selectedDealer.value + '/' + props.selectedSubagent.value, requestOptions, '', (result) => {
            console.log('Spiffee List', result)
            result.map((spiffee, index) => {
                tempSpiffeeList.push({value: spiffee.id, label: spiffee.first_name === null ? spiffee.name:spiffee.first_name + ' ' + spiffee.last_name})
                tempSpiffeePrespiffList.push(spiffee.prespiff_payoff)
            })
            tempSpiffeeList.push({value: 'dealership_spiff', label: props.selectedDealer.label})
            tempSpiffeePrespiffList.push(false)
            setSpiffeeList(tempSpiffeeList)
            setSpiffeePrespiffList(tempSpiffeePrespiffList)
        })
    },[props.selectedDealer])

    return (
        <div style={{width: '360px', height: '530px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Add Spiffee</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span>Spiffee</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={spiffeeList} value={newSpiffeeData[0]}></Dropdown></div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div style={{width: '19px', height: '19px', borderRadius: '4px', border: newSpiffeeData[1] ? 'none':'1px solid ' + props.colors.border, backgroundColor: newSpiffeeData[1] ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Pre-Spiff Payoff</span>
                </div>
                <span style={{marginTop: '15px'}}>Amount / Contract</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 2)} colors={props.colors} data={newSpiffeeData[2]}></TextInput>
                <span style={{marginTop: '15px'}}>Shipping Address</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 3)} index={props.index} indexParent={props.indexParent} data={shippingTypes} value={newSpiffeeData[3]}></Dropdown></div>
                <span style={{marginTop: '15px'}}>ATTN</span>
                <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 4)} colors={props.colors} data={newSpiffeeData[4]}></TextInput>
                <span style={{marginTop: '15px'}}>Spiff Type</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 5)} index={props.index} indexParent={props.indexParent} data={spiffTypes} value={newSpiffeeData[5]}></Dropdown></div>
                <Button onClick={() => {props.setIsOpen(); props.addSpiffee({name: newSpiffeeData[0], amount: newSpiffeeData[2], preSpiff: newSpiffeeData[1], address: {value: newSpiffeeID[3], label: shippingTypes[newSpiffeeID[3] - 1].label}, ATTN: newSpiffeeData[4], spiffType: newSpiffeeData[5], id: newSpiffeeID[0]}); clearReport();}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Add</Button>
            </div>
        </div>
    )
}

export default Main
