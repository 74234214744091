import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'
import Notification from '../elements/Notification.jsx'

import EditRecipients from '../assets/metrics/EditRecipients.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const update = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {''},
        },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]
    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [rowSelected, setRowSelected] = useState([false])

    const [cookies, setCookie] = useCookies([])

    const [selectedRow, setSelectedRow] = useState(-1)

    const [notification, setNotification] = useState('')

    const [accounts, setAccounts] = useState([])
    const [accountsData, setAccountsData] = useState([])

    const [pendingReports, setPendingReports] = useState([])
    const [selectedPendingReport, setSelectedPendingReport] = useState('')
    const [deniedReports, setDeniedReports] = useState([])
    const [selectedDeniedReport, setSelectedDeniedReport] = useState('')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/account/all', requestOptions, '', (result) => {
            setAccountsData(result)
            setAccounts(result.map((item) => ({
                label: item.name,
                value: item.id
            })))
        })
    },[])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/reserve_release/pending?fetch_links=true', requestOptions, '', (result) => {
            console.log(result)
            setPendingReports(result)
        })

        props.tokenSafeAPIRequest('/report/reserve_release/denied?fetch_links=true', requestOptions, '', (result) => {
            console.log(result)
            setDeniedReports(result)
        })
    },[refresh])

    const [showRecipients, setShowRecipients] = useState(false)

    const [data, setData] = useState({
        account: '',
        recipients: [],
        ATTN: '',
        reason: '',
    })

    const onDataChange = (item, newData) => {
        setData((data) => ({
            ...data,
            [item]: newData
        }))
    }

    const [tableValues, setTableValues] = useState([
        [], 
        [],
        [], 
        [], 
        [], 
    ])

    const [tableData, setTableData] = useState([
        [],
        [],
        [],
        [], 
        [], 
    ])

    const saveRecipients = () => {
        onDataChange('recipients', tableData)
    }

    const submitReserveRelease = (data) => {
        data = {...data, recipients: transposeArray(data.recipients)}

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let temp = ({
            ...data,
            account_ID: data.account.value,
            payouts: data.recipients.map((rec) => ({ 
                dealership_ID: (rec[0][0] === '$' || rec[0][0] === '!') ? rec[0].slice(1):null,
                spiffee_ID: (rec[0][0] === '$' || rec[0][0] === '!') ? null:rec[0],
                amount: rec[1],
                address_type: rec[2],
                spiff_type: rec[3],
                subagent_payout: (rec[0][0] === '!') ? true:false
            }))
        })

        console.log(temp)

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(temp),
        }

        props.tokenSafeAPIRequest('/report/reserve_release', requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification(getCurrentTimeFormatted() + ': Reserve Release submitted successfully.')
                setData({
                    account: '',
                    recipients: [],
                    ATTN: '',
                    reason: '',
                })
                setTableData([
                    [],
                    [],
                    [],
                    [], 
                    [], 
                ])
                setTableValues([
                    [],
                    [],
                    [],
                    [], 
                    [], 
                ])
                update();
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const loadPendingReport = (code) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + code + '?fetch_links=true', requestOptions, '', (result, status) => {
            console.log(result)
            setSelectedPendingReport(result)
        })
    }

    
    const loadDeniedReport = (code) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + code + '?fetch_links=true', requestOptions, '', (result, status) => {
            console.log(result)
            setSelectedDeniedReport(result)
        })
    }

    const deletePendingReport = (reportCode) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/reserve_release/' + reportCode, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification(getCurrentTimeFormatted() + ': Reserve release deleted sucessfully.')
                setSelectedPendingReport('')
                update();
            }
            else {setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Reserve Releases"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <MetricHeader colors={props.colors} headerData={headerData} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></MetricHeader>
            <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                <div style={{padding: '20px', borderRight: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500', borderBottom: '1px solid ' + props.colors.border, paddingBottom: '15px'}}>Reports</span>
                    <span style={{paddingTop: '15px'}}>Account</span>
                    <div style={{marginTop: '5px'}}>
                        <Dropdown colors={props.colors} onChange={(e) => onDataChange('account', e)} index={props.index} indexParent={props.indexParent} data={accounts} width={300} value={data.account.label}>Test</Dropdown>
                    </div>
                    <span style={{paddingTop: '15px'}}>Recipients</span>
                    <div style={{marginTop: '5px'}}>
                        <Button colors={props.colors} active={true} Style={{width: '100%'}} onClick={() => setShowRecipients(true)}>{tableValues[0].length !== 0 ? tableValues[0].length + ' Recipients':'Edit Recipients'}</Button>
                    </div>
                    <span style={{paddingTop: '15px'}}>ATTN</span>
                    <div style={{marginTop: '5px'}}>
                        <TextInput prefix="" onChange={(e) => onDataChange('ATTN', e)} colors={props.colors} Style={{width: '100%'}} data={data.ATTN}></TextInput> 
                    </div>
                    <span style={{paddingTop: '15px'}}>Reason</span>
                    <div style={{marginTop: '5px'}}>
                        <TextInput prefix="" onChange={(e) => onDataChange('reason', e)} colors={props.colors} Style={{width: '100%'}} data={data.reason}></TextInput> 
                    </div>
                    <div style={{marginTop: '25px'}}>
                        <Button colors={props.colors} onClick={() => submitReserveRelease(data)} active={true} Style={{width: '100%'}}>Submit</Button>
                    </div>
                </div>
                <div style={{padding: '20px', borderRight: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500', borderBottom: '1px solid ' + props.colors.border, paddingBottom: '15px'}}>Pending Reports</span>
                    <div style={{overflowY: 'scroll', paddingRight: '10px'}}>
                        {selectedPendingReport === '' && pendingReports.map((report, index) => {
                            return (
                                <div onClick={() => loadPendingReport(pendingReports[index].code)} style={{backgroundColor: props.colors.fontInvert, marginTop: '15px', border: '1px solid ' + props.colors.border, borderRadius: '5px', width: '450px', padding: '5px', display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center'}}>
                                    <span style={{marginLeft: '5px', width: '85%', borderRight: '1px solid ' + props.colors.border}}>{report.account_ID.name}</span>
                                    <span style={{marginLeft: 'auto', marginRight: '5px'}}>${report.amount}</span>
                                </div>
                            )
                        })}
                    </div>
                    {selectedPendingReport !== '' && <div style={{display: 'flex', flexDirection: 'column', width: '450px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <span style={{paddingTop: '15px'}}>Account</span>
                            <Button colors={props.colors} onClick={() => deletePendingReport(selectedPendingReport.code)} active={true} Style={{width: '30%', backgroundColor: props.colors.warning, height: '10px', marginLeft: 'auto', marginTop: '15px'}}>Delete</Button>
                        </div>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedPendingReport?.account_ID?.name}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Amount</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>${selectedPendingReport?.amount}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Recipients</span>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {selectedPendingReport?.spiffs?.map((rec) => {
                                return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.dealership_spiff ? rec?.dealership_ID.name:rec?.spiffee_ID.name} - ${rec?.amount}</span>)
                            })}
                            {selectedPendingReport?.profit_splits?.map((rec) => {
                                return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.user_ID.first_name + ' ' + rec?.user_ID.last_name} - ${rec?.net}</span>)
                            })}
                        </div>
                        <span style={{paddingTop: '15px'}}>ATTN</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedPendingReport.ATTN}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Reason</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedPendingReport.reason}</span>
                        </div>
                        <div style={{marginTop: '25px'}}>
                            <Button colors={props.colors} onClick={() => setSelectedPendingReport('')} active={true} Style={{width: '100%', backgroundColor: props.colors.warning}}>Close</Button>
                        </div>
                    </div>}
                </div>
                <div style={{padding: '20px', borderRight: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500', borderBottom: '1px solid ' + props.colors.border, paddingBottom: '15px'}}>Denied Reports</span>
                    <div style={{overflowY: 'scroll', paddingRight: '10px'}}>
                        {selectedDeniedReport === '' && deniedReports.map((report, index) => {
                            return (
                                <div onClick={() => loadDeniedReport(deniedReports[index].code)} style={{backgroundColor: props.colors.fontInvert, marginTop: '15px', border: '1px solid ' + props.colors.border, borderRadius: '5px', width: '450px', padding: '5px', display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center'}}>
                                    <span style={{marginLeft: '5px', width: '85%', borderRight: '1px solid ' + props.colors.border}}>{report.account_ID.name}</span>
                                    <span style={{marginLeft: 'auto', marginRight: '5px'}}>${report.amount}</span>
                                </div>
                            )
                        })}
                    </div>
                    {selectedDeniedReport !== '' && <div style={{display: 'flex', flexDirection: 'column', width: '450px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <span style={{paddingTop: '15px'}}>Account</span>
                            <span onClick={() => setSelectedDeniedReport('')} style={{fontWeight: '600', paddingTop: '15px', marginLeft: 'auto', cursor: 'pointer'}}>X</span>
                        </div>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedDeniedReport?.account_ID?.name}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Amount</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>${selectedDeniedReport?.amount}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Recipients</span>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {selectedDeniedReport?.spiffs?.map((rec) => {
                                return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.dealership_spiff ? rec?.dealership_ID?.name:rec?.spiffee_ID?.name} - ${rec?.amount}</span>)
                            })}
                            {selectedDeniedReport?.profit_splits?.map((rec) => {
                                return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.user_ID?.first_name + ' ' + rec?.user_ID?.last_name} - ${rec?.net}</span>)
                            })}
                        </div>
                        <span style={{paddingTop: '15px'}}>ATTN</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedDeniedReport?.ATTN}</span>
                        </div>
                        <span style={{paddingTop: '15px'}}>Reason</span>
                        <div style={{marginTop: '5px'}}>
                            <span style={{paddingTop: '15px', fontWeight: '500'}}>{selectedDeniedReport?.reason}</span>
                        </div>
                        <div style={{marginTop: '25px'}}>
                            <Button colors={props.colors} onClick={() => setSelectedDeniedReport('')} active={true} Style={{width: '100%', backgroundColor: props.colors.warning}}>Close</Button>
                        </div>
                    </div>}
                </div>
            </div>
            <ModalManager colors={props.colors}>
                <EditRecipients colors={props.colors} isOpen={showRecipients} setIsOpen={() => setShowRecipients(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest} selectedAccount={data.account.value} accounts={accountsData} tableValues={tableValues} tableData={tableData} setTableValues={setTableValues} setTableData={setTableData} onDataChange={onDataChange} saveRecipients={saveRecipients}></EditRecipients>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
