import React, { useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // Import the plugin for jsPDF
import IconButton from '../../elements/IconButton';
import Button from '../../elements/Button';

const Main = (props) => {
    const [loadedData, setLoadedData] = useState([]);
    const [stillGettingData, setStillGettingData] = useState(0);
    const isCancelled = useRef(false); // Used to track if fetching was cancelled

    const handleCancelFetch = () => {
        isCancelled.current = true; // Set flag to true when fetching is cancelled
    };

    const fetchAllData = (exportType) => {
        let skip = 0;
        const limit = 15;
        let allData = [];
        setStillGettingData(1);

        const fetchNext = () => {
            if (isCancelled.current) {
                console.log("Fetching has been cancelled by the user.");
                setStillGettingData(0); // Update state to reflect cancellation
                return;
            }
            getData(skip, limit, (hasMoreData, newData) => {
                if (hasMoreData && newData) {
                    allData = allData.concat(newData);
                    skip += limit;
                    fetchNext();
                } else {
                    if (exportType === 'CSV') {
                        downloadCSV(allData, props.exportKeys, props.fileTitle);
                    } else if (exportType === 'PDF') {
                        downloadPDF(allData, props.exportKeys, props.fileTitle);
                    }
                    setStillGettingData(2);
                }
            });
        };

        fetchNext();
    };

    const getData = (skip, limit, onCompletion) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(props.filterData)
        };

        props.tokenSafeAPIRequest(
            `${props.url}${props.specific ? '/' + props.specific : ''}?limit=${limit}&fetch_links=true&skip=${skip}&sort_field=${props.sort}&start_date=${props.calStartDate}&end_date=${props.calEndDate}&active_only=false`,
            requestOptions,
            '',
            (result, status) => {
                if ('postProcessing' in props) {
                    result = props.postProcessing(result);
                }

                if ('reports' in result) {
                    processResult(result.reports, onCompletion);
                } else if ('accounts' in result) {
                    processResult(result.accounts, onCompletion);
                } else if ('transactions' in result) {
                    processResult(result.transactions, onCompletion);
                } else if ('dealerships' in result) {
                    processResult(result.dealerships, onCompletion);
                } else if ('spiffs' in result) {
                    processResult(result.spiffs, onCompletion);
                } else if ('dppps_and_gap' in result) {
                    processResult(result.dppps_and_gap, onCompletion);
                } else if ('subagents' in result) {
                    processResult(result.subagents, onCompletion);
                } else {
                    processResult(result, onCompletion);
                }
            }
        );
    };

    const processResult = (data, onCompletion) => {
        if (data && data.length > 0) {
            setLoadedData((loadedData) => [...loadedData, ...data]);
            onCompletion(true, data);
        } else {
            onCompletion(false);
        }
    };

    const downloadCSV = (dataArray, keys, filename = 'data.csv') => {
        const csvRows = [];

        // Process each subagent in the data array
        dataArray.forEach((subagentData) => {
            // CSV Title for each subagent
            const subagentName = `${subagentData.first_name} ${subagentData.last_name}`;
            csvRows.push(`Subagent - ${subagentName} - ${props.calStartDate} - ${props.calEndDate}`);
            csvRows.push(`Summary`);
            csvRows.push(`Total Dealerships, Total IS Net, Total Reports`);
            csvRows.push(
            `${subagentData.total_dealerships}, ${subagentData.total_is_net}, ${subagentData.total_reports}`
            );
            csvRows.push(""); // Empty row for separation

            // Add Reports Table Header
            csvRows.push(`Reports`);
            csvRows.push(
            `Quantity, Report Type, Submitted By, Subagent, Dealer, Admin, IS Net/c, IS Net Total`
            );

            // Process Reports for the current subagent
            subagentData.reports.forEach((report) => {
            const quantity = report.quantity || 0;
            const reportType = report.report_type || "N/A";
            const submittedBy = report.submitted_by
                ? `${report.submitted_by.first_name} ${report.submitted_by.last_name}`
                : "N/A";
            const subagent = report.subagent_ID
                ? `${report.subagent_ID.first_name} ${report.subagent_ID.last_name}`
                : "N/A";
            const dealer = report.dealership_ID ? report.dealership_ID.name : "N/A";
            const admin = report.admin_ID ? report.admin_ID.name : "N/A";
            const isNetC = report.inside_sales_payout || 0;
            const isNetTotal = report.net || 0;

            // Add report data row
            csvRows.push(
                `${quantity}, ${reportType}, ${submittedBy}, ${subagent}, ${dealer}, ${admin}, ${isNetC}, ${isNetTotal}`
            );
            });

            csvRows.push(""); // Add a separator after each subagent block
        });

        // Combine all rows into CSV format
        const csvContent = csvRows.join("\n");

        // Create a Blob and trigger file download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Subagents_Report_${props.calStartDate}_to_${props.calEndDate}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function addCommas(number) {
        // Convert the number to a string and use a regular expression to add commas
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const downloadPDF = (data, keys, filename = 'data.pdf') => {
        console.log(data)
        const doc = new jsPDF({ orientation: 'landscape' });
    
        // // Add title
        doc.text(props.fileTitle || 'Report', 20, 10);

        let pageCount = 1;

        const addPageNumbers = (doc) => {
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height;
            const pageWidth = pageSize.width;
    
            // Add footer with page number
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.text(
                    `Page ${i}`,
                    pageWidth - 30,
                    pageHeight - 10
                );
            }
        };

        data.map((agent) => {
            doc.addPage('landscape'); 

            doc.text(`Agent: ${agent.first_name + ' ' + agent.last_name} | ${props.calStartDate} - ${props.calEndDate}`, 14, 10);
            doc.text(`${'Summary'}`, 14, 18);

            doc.autoTable({
                startY: 22,
                head: [['Total Dealerships', 'Total IS Net', 'Total Reports']],
                body: [[
                    `${addCommas(agent.total_dealerships)}`, 
                    `$${addCommas(agent.total_is_net.toFixed(2))}`, 
                    `${addCommas(agent.total_reports)}`, 
                ]],
                styles: {
                    lineColor: [200, 200, 200], // Light grey border lines
                    lineWidth: 0.1              // Thin border lines
                },
                bodyStyles: {
                    fillColor: [0, 0, 0],             // Disable row fill color completely
                },
                headStyles: {
                    fillColor: false,            // No background for header row
                    textColor: 0,                // Black text
                    lineColor: [200, 200, 200],  // Light grey border lines for header
                    lineWidth: 0.1
                },
                tableLineColor: [200, 200, 200], // Light grey border around table
                tableLineWidth: 0.1,
                willDrawCell: function(data) {
                    data.cell.styles.fillColor = false; // Override alternating row colors
                }
            });

            doc.autoTable({
                startY: 45,
                head: [['Quantity', 'Report Type', 'Submitted By', 'Subagent', 'Dealer', 'Admin', 'IS Net/c', 'IS Net Total']],
                body: agent.reports.map((report) => [
                    report.quantity,
                    report.report_subtype === null ? report.report_type:report.report_subtype,
                    report.submitted_by.first_name + " " + report.submitted_by.last_name,
                    report?.subagent_ID?.first_name + " " + report?.subagent_ID?.last_name,
                    report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name,
                    report.admin_ID === null ? 'No Admin':report?.admin_ID?.name,
                    '$' + report.inside_sales_payout,
                    '$' + report.total_is_net,
                ]),
                styles: {
                    lineColor: [200, 200, 200], // Light grey border lines
                    lineWidth: 0.1              // Thin border lines
                },
                bodyStyles: {
                    fillColor: [0, 0, 0],             // Disable row fill color completely
                },
                headStyles: {
                    fillColor: false,            // No background for header row
                    textColor: 0,                // Black text
                    lineColor: [200, 200, 200],  // Light grey border lines for header
                    lineWidth: 0.1
                },
                didDrawPage: (data) => {
                    pageCount++;
                },
                tableLineColor: [200, 200, 200], // Light grey border around table
                tableLineWidth: 0.1,
                willDrawCell: function(data) {
                    data.cell.styles.fillColor = false; // Override alternating row colors
                }
            });
        })

        addPageNumbers(doc);

        doc.save(filename + ' - ' + props.calStartDate + ' - ' + props.calEndDate);
    };
    

    return (
        <div style={{ width: '360px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexBasis: '10%', display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: '500', fontSize: '20px', flexBasis: '99%' }}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{ borderRadius: '3px', backgroundColor: props.colors.border, width: '24px' }} size="16px">
                    <div onClick={() => { handleCancelFetch(); props.setIsOpen() }} style={{ paddingLeft: '3px', paddingRight: '3px' }}><span onClick={() => { handleCancelFetch(); props.setIsOpen() }}>x</span></div>
                </IconButton>
            </div>
            <div style={{ flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                <span style={{ fontWeight: '500', fontSize: '16px' }}>Downloaded Rows: {loadedData.length}</span>
                <Button colors={props.colors} active={true} Style={{ marginTop: '10px' }} onClick={() => fetchAllData('CSV')}>Download CSV</Button>
                <Button colors={props.colors} active={true} Style={{ marginTop: '10px' }} onClick={() => fetchAllData('PDF')}>Download PDF</Button>
            </div>
        </div>
    );
};

export default Main;
