import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Main = (props) => {

    console.log(props)

    const [loadedData, setLoadedData] = useState([])

    const isCancelled = useRef(false);

    const handleCancelFetch = () => {
        isCancelled.current = true;
    };

    function addCommas(number) {
        // Convert the number to a string and use a regular expression to add commas
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function transformInputDate(inputDate) {
        // Parse the input date string
        const date = new Date(inputDate);
      
        // Extract the year, month, and day from the input date
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
        const day = date.getUTCDate();
      
        // Format the month and day to ensure they are in 'MM' or 'DD' format
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
      
        // Construct the new date string
        // If you intended to literally use '2123' as the year, replace 'year' with '2123' in the next line
        const newDateStr = `${year}-${formattedMonth}-${formattedDay}`;
      
        return newDateStr;
      }
      
    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    function downloadCSV(data, keys, filename = 'data.csv', info) {
        console.log(data, keys, filename, info)
        data = data.map((table) => {
            return transposeArray(table)
        })
        info = info.map((table) => {
            return {
                admin_name: table?.admin_ID?.name,
                report_type: table?.report_subtype,
                total_contract_count: table?.total_contract_count,
                total_net: table?.total_net,
                total_contract_gross: table?.total_contract_gross,
                total_spiffee_payout: table?.total_spiffee_payout,
            }
        })
        console.log(data, keys, filename, info)
        exportToCsv("report.csv", info, data);
    }

    function exportToCsv(filename, admins, reports) {
        // Merge admin data with report data
        const mergedData = admins.map((admin, i) => ({
            ...admin,
            details: reports[i]
        }));
    
        // Remove all commas from merged data
        mergedData.forEach(adminData => {
            Object.keys(adminData).forEach(key => {
                if (typeof adminData[key] === 'string') {
                    adminData[key] = adminData[key].replace(/,/g, ''); // Remove commas from strings
                }
            });
    
            // Remove commas from details data
            adminData.details = adminData.details.map(detail =>
                detail.map(item => (typeof item === 'string' ? item.replace(/,/g, '') : item))
            );
        });
    
        // Create CSV content
        let csvContent = "";
    
        // Add Summary section
        csvContent += "Summary\r\n";
        let totalNetSum = 0;
        let totalContractCountSum = 0;
    
        mergedData.forEach((adminData) => {
            const { admin_name, report_type, total_net, total_contract_count } = adminData;
            csvContent += `${admin_name},${report_type},${total_net},${total_contract_count}\r\n`;
            totalNetSum += total_net;
            totalContractCountSum += total_contract_count;
        });
    
        // Add Total row
        csvContent += `total,,${totalNetSum.toFixed(2)},${totalContractCountSum.toFixed(2)}\r\n\r\n`;
    
        console.log(mergedData);
    
        mergedData.forEach((adminData) => {
            // Add Admin title
            csvContent += `${adminData.admin_name} - ${adminData.report_type}\r\n`;
            // Add Headers
            csvContent += "Dealer,Contract Type,Gross/c,Spiffs/c,Net/c,CC/c,QTY,Total Net,Total CC,Unique Identifier\r\n";
            // Add report data
            adminData.details.forEach((detail) => {
                csvContent += detail.join(",") + "\r\n";
            });
            // Add a newline to separate sections
            csvContent += "\r\n";
        });
    
        // Create a blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            // Create a link element and download the CSV file
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection for download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    

    function downloadPDF(data, keys, filename = 'report.pdf', info, startDate, endDate) {
        console.log(data, keys, filename, info)
        data = data.map((table) => {
            return transposeArray(table)
        })

        console.log(info)

        info = info.map((table) => {
            return {
                admin_name: table?.admin_ID?.name === '' ? 'No Admin':table?.admin_ID?.name,
                report_type: table?.report_subtype,
                total_contract_count: table?.total_contract_count,
                total_net: table?.total_net,
                total_contract_gross: table?.total_contract_gross,
                total_spiffee_payout: table?.total_spiffee_payout,
                total_dppp_amount: table?.total_dppp_amount,
                total_inside_sales_payout: table?.total_inside_sales_payout,
                total_gapp_amount: table?.total_gapp_amount,
            }
        })
        // console.log(data, keys, filename, info)
        resolveReportIDs(filename, info, data, startDate, endDate)
        // exportToPdf(filename, info, data);
    }

    const resolveReportIDs = (filename, admins, reports, startDate, endDate) => {

        // Create a new jsPDF instance
        const doc = new jsPDF({orientation: 'landscape'});
    
        const mergedData = admins.map((admin, i) => ({
            ...admin,
            details: reports[i]
        }));
    
        console.log(mergedData);

        let reportCodeList = []

        mergedData.map(table => {
            table.details.map(report => {
                reportCodeList.push(report[9])
            })
        })
    
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(reportCodeList)
        };
    
        // Wrapping the API request inside a promise
        const fetchReportData = () => {
            return new Promise((resolve, reject) => {
                props.tokenSafeAPIRequest(`/report/full`, requestOptions, '', (result) => {
                    if (result) {
                        console.log(result)
                        matchCodesToReports(result)
                        resolve(result);
                    } else {
                        reject('Error fetching report data');
                    }
                });
            });
        };

        const matchCodesToReports = (reportCodeListResponse) => {
            mergedData.map(table => {
                table.details.map(report => {
                    reportCodeListResponse.map(resolvedReport => {
                        if (report[9] === resolvedReport.code)
                        {
                            let tempSpiffs = ""
                            let tempReserveInputs = ""
                            let tempFlats = ""
                            let tempSplits = ""
                            resolvedReport.spiffs.map((spiff) => {
                                tempSpiffs = tempSpiffs + ' | ' + (spiff.dealership_spiff ? spiff.dealership_ID.name:spiff.spiffee_ID.name) + ' - $' + spiff.amount.toFixed(2)
                            })
                            resolvedReport.reserve_inputs.map((input) => {
                                tempReserveInputs = tempReserveInputs + ' | ' + input.account_ID.name + ' - $' + input.amount.toFixed(2)
                            })
                            resolvedReport.flats.map(flat => {
                                tempFlats = tempFlats + ' | ' + flat.user_ID.first_name + ' ' + flat.user_ID.last_name + " - $" + flat.amount.toFixed(2)
                            })
                            resolvedReport?.profit_splits?.map(split => {
                                tempSplits = tempSplits + " | " + split.user_ID.first_name + ' ' + split.user_ID.last_name + " - $" + split.net.toFixed(2)
                            })
                            report[9] = tempSpiffs; // Assuming you need the date
                            report[10] = tempReserveInputs;
                            report[11] = tempFlats
                            report[12] = tempSplits
                            report[13] = resolvedReport.dppp_amount
                            report[14] = resolvedReport.gap_amount
                            report[15] = resolvedReport.inside_sales_payout
                            report[16] = resolvedReport.reason
                        }
                    })
                })
            })
        }

        // Call fetchReportData and chain the promise to trigger buildPDF once the data is fetched
        fetchReportData()
        .then(() => {
            buildPDF(); // Trigger the buildPDF function once the API request completes
        })
        .catch((error) => {
            console.error(error);
        });
    
        // This function is called once all promises are resolved
        const buildPDF = () => {
            console.log(props)
            // Initialize the PDF in landscape mode
            const doc = new jsPDF({ orientation: 'landscape' });
            let pageCount = 1; // Start page numbering from 1
            const agentName = props.selectedAgent.subagent_name; // Access the agent's name
        
            // Add Summary section
            let totalNetSum = 0;
            let totalContractCountSum = 0;
            const summaryRows = [];

            console.log(mergedData)
        
            mergedData.forEach((adminData) => {
                // Prepend $ to total_net
                summaryRows.push([
                    adminData.admin_name, 
                    adminData.report_type, 
                    `$${addCommas(adminData.total_net.toFixed(2))}`, 
                    `${addCommas(adminData.total_contract_count.toFixed(2))}`
                ]);
                totalNetSum += adminData.total_net;
                totalContractCountSum += adminData.total_contract_count;
            });
        
            // Add header for summary with agent's name
            doc.text(`Agent: ${agentName} | ${startDate} - ${endDate}`, 14, 10);
            doc.text(`${'Summary'}`, 14, 17.5);
        
            // Add Summary table with light grey border and no row fill color
            doc.autoTable({
                startY: 20,
                head: [['Admin Name', 'Report Type', 'Total Net', 'Total Contract Count']],
                body: summaryRows,
                styles: {
                    lineColor: [200, 200, 200], // Light grey border lines
                    lineWidth: 0.1             // Thin border lines
                },
                bodyStyles: {
                    fillColor: false             // Disable row fill color completely
                },
                headStyles: {
                    fillColor: false,            // No background for header row
                    textColor: 0,                // Black text
                    lineColor: [200, 200, 200],  // Light grey border lines for header
                    lineWidth: 0.1
                },
                tableLineColor: [200, 200, 200], // Light grey border around table
                tableLineWidth: 0.1,
                willDrawCell: function(data) {
                    data.cell.styles.fillColor = false; // Override alternating row colors
                }
            });
        
            // Add Total row with $ in front of Total Net
            doc.text(`Total Net: $${totalNetSum.toFixed(2)}`, 14, doc.lastAutoTable.finalY + 10);
            doc.text(`Total Contract Count: ${totalContractCountSum.toFixed(2)}`, 14, doc.lastAutoTable.finalY + 16);
        
            // Add page number for the first page
            doc.text(`Page ${pageCount}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10);
        
            // Increment page count after the first page
            pageCount++;

            console.log(mergedData)

            // For each adminData, add their details in a new landscape page
            mergedData.forEach((adminData) => {
                doc.addPage('landscape');  // Ensure each page added is landscape
        
                // Add agent's name to the top of each page
                doc.text(`Agent: ${agentName} | ${startDate} - ${endDate}`, 14, 10);
        
                doc.text(`${adminData.admin_name === undefined ? 'No Admin' : adminData.admin_name} - ${adminData.report_type}`, 14, 17.5);
                doc.text(`${'Summary'}`, 14, 25);
        
                // Prepend $ to the values in the table body
                doc.autoTable({
                    startY: 30,
                    head: [['Gross', 'Spiffs', 'Net', 'Contract Count','DPPP','GAP Plus', 'IS']],
                    body: [[
                        `$${addCommas(adminData.total_contract_gross.toFixed(2))}`, 
                        `$${addCommas(adminData.total_spiffee_payout.toFixed(2))}`, 
                        `$${addCommas(adminData.total_net.toFixed(2))}`, 
                        `${addCommas(adminData.total_contract_count.toFixed(2))}`,
                        `$${addCommas(adminData.total_dppp_amount.toFixed(2))}`, 
                        `$${addCommas(adminData.total_gapp_amount.toFixed(2))}`, 
                        `$${addCommas(adminData.total_inside_sales_payout.toFixed(2))}`, 
                    ]],
                    styles: {
                        lineColor: [200, 200, 200], // Light grey border lines
                        lineWidth: 0.1              // Thin border lines
                    },
                    bodyStyles: {
                        fillColor: [0, 0, 0],             // Disable row fill color completely
                    },
                    headStyles: {
                        fillColor: false,            // No background for header row
                        textColor: 0,                // Black text
                        lineColor: [200, 200, 200],  // Light grey border lines for header
                        lineWidth: 0.1
                    },
                    tableLineColor: [200, 200, 200], // Light grey border around table
                    tableLineWidth: 0.1,
                    willDrawCell: function(data) {
                        data.cell.styles.fillColor = false; // Override alternating row colors
                    }
                });
        
                doc.text(`${'Reports'}`, 14, 55);
        
                // Prepare data for table with $ in Gross/c, Spiffs/c, Net/c, and Total Net
                const tableHead = [['Dealer', 'Contract Type', 'Gross/c', 'Spiffs/c', 'Net/c', 'CC/c', 'QTY', 'Total Net', 'Total CC', 'Spiffs/c', 'Reserve Inputs/c', 'Flats/c', 'Splits/c', 'DPPP/c', 'GAP/c', 'IS/c', 'Reason']];
                const tableBody = adminData.details.map(detail => [
                    detail[0],  // Dealer
                    detail[1],  // Contract Type
                    `$${addCommas(parseFloat(detail[2]).toFixed(2))}`,  // Gross/c
                    `$${addCommas(parseFloat(detail[3]).toFixed(2))}`,  // Spiffs/c
                    `$${addCommas(parseFloat(detail[4]).toFixed(2))}`,  // Net/c
                    `${addCommas(parseFloat(detail[5]).toFixed(2))}`,  // CC/c
                    detail[6],  // QTY
                    `$${addCommas(parseFloat(detail[7]).toFixed(2))}`,  // Total Net
                    `${addCommas(parseFloat(detail[8]).toFixed(2))}`,  // Total CC
                    detail[9],  // Spiffs
                    detail[10],  // Reserve Inputs
                    detail[11],
                    detail[12],
                    `$${addCommas(detail[13].toFixed(2))}`,
                    `$${addCommas(detail[14].toFixed(2))}`,
                    `$${addCommas(detail[15].toFixed(2))}`,
                    detail[16]
                ]);
        
                // Render the detailed report table with custom borders and no row fill
                doc.autoTable({
                    startY: 60,
                    head: tableHead,
                    body: tableBody,
                    styles: {
                        lineColor: [200, 200, 200], // Light grey border lines
                        lineWidth: 0.1,              // Thin border lines
                        fillColor: false,
                    },
                    bodyStyles: {
                        fillColor: false             // Disable row fill color completely
                    },
                    headStyles: {
                        fillColor: false,            // No background for header row
                        textColor: 0,                // Black text
                        lineColor: [200, 200, 200],  // Light grey border lines for header
                        lineWidth: 0.1
                    },
                    tableLineColor: [200, 200, 200], // Light grey border around table
                    tableLineWidth: 0.1,
                    columnStyles: {
                        16: { maxCellWidth: 60, minCellWidth: 15 }, // Set the "Reason" column (index 16) to have a width of 300 pixels
                        0: { cellWidth: 15 }, // Example: Set minimum width for "Dealer" column
                        1: { minCellWidth: 15 }, // Example: Set minimum width for "Contract Type" column
                        2: { minCellWidth: 15 },
                        3: { minCellWidth: 15 },
                        4: { minCellWidth: 15 },
                        5: { minCellWidth: 15 },
                        6: { minCellWidth: 15 },
                        7: { minCellWidth: 15 },
                        8: { minCellWidth: 15 },
                        9: { maxCellWidth: 45, minCellWidth: 15 },
                        10: { maxCellWidth: 45, minCellWidth: 15 },
                        11: { maxCellWidth: 45, minCellWidth: 15 },
                        12: { minCellWidth: 15 },
                        13: { minCellWidth: 15 },
                        14: { minCellWidth: 15 },
                        15: { minCellWidth: 15 },
                    },
                    willDrawCell: function(data) {
                        data.cell.styles.fillColor = false; // Override alternating row colors
                    }
                });
        
                // Add page number at the bottom
                doc.text(`Page ${pageCount}`, doc.internal.pageSize.getWidth() - 30, doc.internal.pageSize.getHeight() - 10);
        
                // Increment page count
                pageCount++;
            });
        
            // Save the PDF
            doc.save(filename + ' - ' + agentName + ' - ' + startDate + ' - ' + endDate);
        };                      
    };
    
    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={() => {handleCancelFetch(); props.setIsOpen()}} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={() => {handleCancelFetch(); props.setIsOpen()}}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', fontSize:'16px'}}>Downloaded Rows: {loadedData.length}</span>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadCSV(props.reportData, props.exportKeys, props.fileTitle, props.admin_report_info, props.startDate, props.endDate)}>Download CSV</Button>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadPDF(props.reportData, props.exportKeys, props.fileTitle, props.admin_report_info, props.startDate, props.endDate)}>Download PDF</Button>
            </div>
        </div>
    )
}

export default Main
