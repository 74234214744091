import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [newReserveData, setNewReserveData] = useState([
        '',
        0,
        '',
    ])

    const [newReserveID, setNewReserveID] = useState([
        '',
        0,
        '',
    ])

    const [reserveAccounts, setReserveAccounts] = useState([])

    const onChange = (e, index) => {
        setNewReserveData((newSpiffeeData) => newSpiffeeData.map((dataPoint, indexParent) => {
            if (index === indexParent)
            {
                if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
            } else {return dataPoint}
        }))
        setNewReserveID((newSpiffeeID) => newSpiffeeID.map((dataPoint, indexParent) => {
            if (index === indexParent)
            {
                if (e.value === undefined)
                    {
                        return e;
                    }
                    return e.value;
            } else {return dataPoint}
        }))
    }

    const clearReport = () => {
        console.log('clearing report!')
        setNewReserveData([
            '',
            0,
            '',
        ])
        setNewReserveID([[
            '',
            0,
            '',
        ]])
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        console.log('Loading Reserve Accounts', props.selectedDealer.value)

        props.tokenSafeAPIRequest('/account/dealership/' + props.selectedDealer.value + '/' + props.selectedSubagent.value, requestOptions, '', (result) => {
            console.log(result)
            let tempReserveAccounts = []
            result.map((account, index) => {
                tempReserveAccounts.push({value: account.id, label: account.name})
            })
            console.log(tempReserveAccounts)
            setReserveAccounts(tempReserveAccounts)
        })
    },[])

    return (
        <div style={{width: '360px', height: '350px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Add Reserve Input</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '15px'}}>Account</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={reserveAccounts} value={newReserveData[0]}></Dropdown></div>
                <span style={{marginTop: '15px'}}>Amount</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 1)} colors={props.colors} data={newReserveData[1]}></TextInput>
                <span style={{marginTop: '15px'}}>Reason</span>
                <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 2)} colors={props.colors} data={newReserveData[2]}></TextInput>
                <Button onClick={() => {
                props.setIsOpen();
                props.addReserveInput({
                    account: newReserveData[0],
                    amount: newReserveData[1],
                    reason: newReserveData[2],
                    id: newReserveID[0]
                });
                clearReport();}}
                Style={{marginTop: '20px'}} active={true} colors={props.colors}>Add</Button>
            </div>
        </div>
    )
}

export default Main
