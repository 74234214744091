import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue, m } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../../elements/UserTab.jsx'
import Button from '../../elements/Button.jsx'

import CommissionReceivedImage from '../../media/icons/icons8-open-email-96.png'
import Agent from '../../media/icons/icons8-agent-96.png'
import OpenEnvelope from '../../media/icons/icons8-open-envelope-96.png'
import Adjustments from '../../media/icons/icons8-adjustment-96.png'
import BarChart from '../../media/icons/icons8-bar-chart-96.png'
import Customer from '../../media/icons/icons8-customer-96.png'
import Holiday from '../../media/icons/icons8-christmas-berry-96.png'
import View from '../../media/icons/icons8-view-96-2.png'
import Search from '../../media/icons/icons8-search-bar-96.png'
import Expense from '../../media/icons/icons8-expense-96.png'
import Settings from '../../media/icons/icons8-settings-96.png'
import Employees from '../../media/icons/icons8-employees-96.png'
import Dealer from '../../media/icons/icons8-land-sales-96.png'
import Insurance from '../../media/icons/icons8-insurance-96.png'
import Spiffee from '../../media/icons/icons8-female-meeting-96.png'
import OS from '../../media/icons/icons8-badge-96.png'
import MGMT from '../../media/icons/icons8-management-96.png'
import TransientClaim from '../../media/icons/icons8-requirement-96.png'
import MoneyTransfer from '../../media/icons/icons8-money-transfer-96.png'
import TransferMoney from '../../media/icons/icons8-transfer-money-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    function hasViewPermission(key, data, title) {
        //data = master list of quicklinks
        //key is permission we are looking into
        // Check if the key exists in the object
        console.log(key, data)

        if (title === 'Permissions' && (data.static_role !== 'superadmin' && data.static_role !== 'admin'))
        {
            return false
        }

        if (Object.keys(data.allow).length === 1 && data.allow.hasOwnProperty("*") && data.allow["*"].length === 1 && data.allow["*"][0] === "*") {
            return true;
        }
        if (data.allow.hasOwnProperty(key)) {
            // Check if "View" is in the array of values for the key
            return data.allow[key].includes("View");
        } else {
            // If the key does not exist in the object, return false
            return false;
        }
    }

    const allLinks = [
        {
            title: 'Commission Received Reports',
            button: 'Approve',
            img: CommissionReceivedImage,
            link: '/reports/commissionreceived',
            permissionKey: 'CommissionReceivedReport',
            permissionValue: 'View'
        },
        {
            title: 'Thursday Verification Reports',
            button: 'Submit',
            img: Agent,
            link: '/reports/thursdayreporting',
            permissionKey: 'ThursdayReport',
            permissionValue: 'View'
        },
        {
            title: 'Saturday Reports',
            button: 'Submit',
            img: Agent,
            link: '/reports/saturdayreporting',
            permissionKey: 'SaturdayReport',
            permissionValue: 'View'
        },
        {
            title: 'Reserve Release Request',
            button: 'Submit',
            img: OpenEnvelope,
            link: '/reports/reserveRelease',
            permissionKey: 'ReserveReleaseReport',
            permissionValue: 'View'
        },
        {
            title: 'Adjustment Reports',
            button: 'Approve',
            img: Adjustments,
            link: '/reports/adjustmentreporting',
            permissionKey: 'AdjustmentReport',
            permissionValue: 'View'
        },
        {
            title: 'Prespiff Report',
            button: 'Submit',
            img: BarChart,
            link: '/reports/prespiffreporting',
            permissionKey: 'PreSpiffReport',
            permissionValue: 'View'
        },
        {
            title: 'Holiday Card List',
            button: 'View',
            img: Holiday,
            link: () => props.setHolidayCardListModal(true),
            permissionKey: 'HolidayCard',
            permissionValue: 'View'
        },
        {
            title: 'Reserve View Request',
            button: 'Submit',
            img: View,
            link: () => props.setReserveTotalsRequest(true),
            permissionKey: 'ReserveReleaseReport',
            permissionValue: 'View'
        },
        {
            title: 'Dealer Search',
            button: 'View',
            img: Search,
            link: '/dealersearch',
            permissionKey: 'Dealership',
            permissionValue: 'View'
        },
        {
            title: 'Expense Reports',
            button: 'Submit',
            img: Expense,
            link: '/reports/expense',
            permissionKey: 'ExpenseReport',
            permissionValue: 'View'
        },
        {
            title: 'User Settings',
            button: 'View',
            img: Settings,
            link: '/settings',
            permissionKey: 'User',
            permissionValue: 'View'
        },
        {
            title: 'Transient Claims',
            button: 'Submit',
            img: TransientClaim,
            link: '/reports/transientclaims',
            permissionKey: 'TransientClaimsReport',
            permissionValue: 'View'
        },
        {
            title: 'Approve Reserve Release',
            button: 'Approve',
            img: MoneyTransfer,
            link: '/reports/reservereleaserequests',
            permissionKey: 'ReserveReleaseReport',
        },
        {
            title: 'Approve View Reserve Totals',
            button: 'Approve',
            img: View,
            link: '/reports/reservetotals',
            permissionKey: 'ReserveReleaseReport',
        },
        {
            title: '>50% Spiff / >25% Flats Reports',
            button: 'Approve',
            img: TransferMoney,
            link: '/reports/spiffsGreaterThan50',
            permissionKey: 'SaturdayReport',
        },
        {
            title: 'Verification Reports (OS)',
            button: 'Approve',
            img: Agent,
            link: '/reports/thursdayreportingos',
            permissionKey: 'ThursdayReport',
        },
        {
            title: 'Saturday Reports (OS)',
            button: 'Approve',
            img: Agent,
            link: '/reports/saturdayreportingos',
            permissionKey: 'SaturdayReport',
        },
        {
            title: 'Commission Reports (OS)',
            button: 'Submit',
            img: CommissionReceivedImage,
            link: '/reports/commissionreceivedos',
            permissionKey: 'CommissionReceivedReport',
        },
        {
            title: 'Adjustment Reports (OS)',
            button: 'Submit',
            img: Adjustments,
            link: '/reports/adjustmentreportingos',
            permissionKey: 'AdjustmentReport',
        },
        {
            title: 'Pre-spiff Reports (OS)',
            button: 'Approve',
            img: BarChart,
            link: '/reports/prespiffreportingos',
            permissionKey: 'PreSpiffReport',
        },
        {
            title: 'Expense Reports (OS)',
            button: 'Approve',
            img: Expense,
            link: '/reports/expenseos',
            permissionKey: 'ExpenseReport',
        },
        {
            title: 'Manage Subagents',
            button: 'Edit',
            img: Employees,
            link: '/manage/subagents',
            permissionKey: 'User',
        },
        {
            title: 'Manage Dealers',
            button: 'Edit',
            img: Dealer,
            link: '/manage/dealers',
            permissionKey: 'Dealership',
        },
        {
            title: 'Manage Admins',
            button: 'Edit',
            img: Insurance,
            link: '/manage/admins',
            permissionKey: 'Admin',
        },
        {
            title: 'Manage Spiffees',
            button: 'Edit',
            img: Spiffee,
            link: '/manage/spiffees',
            permissionKey: 'Spiffee',
        },
        {
            title: 'Manage Office Staff',
            button: 'Edit',
            img: OS,
            link: '/manage/os',
            permissionKey: 'User',
        },
        {
            title: 'Manage MGMT',
            button: 'Edit',
            img: MGMT,
            link: '/manage/mgmt',
            permissionKey: 'User',
        },
        {
            title: 'Manage Reserve Accounts',
            button: 'Edit',
            img: Dealer,
            link: '/manage/reserveAccounts',
            permissionKey: 'User',
        },
        {
            title: 'Permissions',
            button: 'Edit',
            img: MGMT,
            link: '/permissions',
            permissionKey: 'User',
        },
        {
            title: 'Edit Giftcard Fees',
            button: 'Edit',
            img: Adjustments,
            link: () => props.setEditGiftCardFeesModal(true),
            permissionKey: 'GiftCardFees',
        },
    ]

    const [quickLinks, setQuickLinks] = useState([])

    function filterAndOutputValues(keysArray, keyValueList) {
        // Iterate through each key in the keys array
        keysArray.forEach(key => {
        // Check if the key is not present in the keyValueList
            if (!keyValueList.hasOwnProperty(key)) {
            // If not present, add the key with an empty array as its value
                keyValueList[key] = [];
            }
        });
      
        return keyValueList;
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
            role: cookies['role'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, tokenValues, (result2, status) => {
            console.log(result2)
            if (result2.allow.hasOwnProperty('*'))
            {
                setQuickLinks(allLinks)
                return '';
            }
            let tempQuickLinks = []
            allLinks.map((link2, index2) => {
                result2.allow.Quicklinks.map((link, index) => {
                    if (link === allLinks[index2].title)
                    {
                        tempQuickLinks.push(allLinks[index2])
                    }
                })
            })
            setQuickLinks(tempQuickLinks)
        })
    },[])

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', padding: '20px', paddingLeft: '50px', paddingRight: '50px', minWidth: '450px'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <span style={{fontWeight: '500', fontSize: '20px'}}>Quick Links</span>
            </div>
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', boxSizing: 'border-box', paddingRight: '15px'}}>
                {quickLinks.map((item, index) => { 
                    return typeof item.link === 'function' ? 
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '10px', padding: '10px', marginTop: '10px'}}>
                            <div className='center'>
                                <div style={{backgroundColor: props.colors.border, padding: '15px', borderRadius: '100px'}}>
                                    <img width="28px" style={{minWidth: '28px', minHeight: '28px'}} src={item.img}></img>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                <span style={{fontWeight: '500'}}>{item.title}</span>
                                <Button onClick={() => item.link()} active={true} colors={props.colors} Style={{width: '110px', borderRadius: '5px', padding: '2px', marginTop: '5px', fontSize: '14px'}}>{item.button}</Button>
                            </div>
                        </div>
                        :
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '10px', padding: '10px', marginTop: '10px'}}>
                            <div className='center'>
                                <div style={{backgroundColor: props.colors.border, padding: '15px', borderRadius: '100px'}}>
                                    <img width="28px" style={{minWidth: '28px', minHeight: '28px'}} src={item.img}></img>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                                <span style={{fontWeight: '500'}}>{item.title}</span>
                                <Link to={item.link}><Button active={true} colors={props.colors} Style={{width: '110px', borderRadius: '5px', padding: '2px', marginTop: '5px', fontSize: '14px'}}>{item.button}</Button></Link>
                            </div>
                        </div>
                })}
            </div>
        </div>
    )
}

export default Main
