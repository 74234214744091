import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'
import Approve from '../media/icons/icons8-approve-96.png'
import Deny from '../media/icons/icons8-cancel-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'
import DenyConfirm from '../assets/DenyConfirm.jsx'
import DeleteConfirm from '../assets/DenyConfirm.jsx'

import Notification from '../elements/Notification.jsx'

import ViewRecipients from '../assets/approveReserveRelease/ViewRecipients.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [recipientModal, setRecipientModal] = useState(false)
    const [selectedReport, setSelectedReport] = useState(-1)
    const [reports, setReports] = useState([])

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Agent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Account',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Recipient',
            displayElement: (props) => (<Button colors={props.colors} onClick={() => {setSelectedReport(props.index); setRecipientModal(true)}} active={true} Style={{height: '20px', borderRadius: '5px', fontSize: '14px'}}>View</Button>),
            default: '',
        },
        {
            title: 'Reason',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap', maxWidth: '425px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Approve / Deny',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => approveReport(props.index, props.tableData)} Style={{backgroundColor: props.colors.primary, marginLeft: '10px'}} size={27} image={Approve} colors={props.colors}></IconButton>
                    <IconButton onClick={() => denyReport(props.index, props.tableData)} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={27} image={Deny} colors={props.colors}></IconButton>
                </div>),
            default: '',
        },
    ])

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return 'Subagents Under Dealer'}
        if (payee === 3) {return 'Inside Sales'}
    }

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
    }

    const deleteRow = (index) => {
        console.log('Deleted Row')
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated')
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    return e;
                }
                return data;
            })
        }))
    }

    const submitSelectedReports = () => {

    }

    const [rowSelected, setRowSelected] = useState([false])

    const [manageDenyModal, setManageDenyModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Agent
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross / Contract
    ])
    
    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]], 
        [[]],
        [[]],
        [[]],
    ])

    const showTableData = () => {
        console.log(manageDenyModal)
    }

    const [refresh, setRefresh] = useState('')

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/reserve_release/need_approval?fetch_links=true', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            setReports(result)
            let tempRequestData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempRequestDisplayData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            result.map((request, index) => {
                tempRequestDisplayData[0].push(request.submitted_by.first_name + ' ' + request.submitted_by.last_name)
                tempRequestDisplayData[1].push(request.account_ID.name)
                tempRequestDisplayData[2].push(request.amount)
                tempRequestDisplayData[3].push('')
                tempRequestDisplayData[4].push(request.reason)
                tempRequestDisplayData[5].push(request.id)

                tempRequestData[0].push(request.submitted_by.id)          
                tempRequestData[1].push(request.account_ID.id)  
                tempRequestData[2].push(request.amount)  
                tempRequestData[3].push('')
                tempRequestData[4].push(request.reason)  
                tempRequestData[5].push(request.id)        
            })

            setTableData(tempRequestData)
            setTableValues(tempRequestDisplayData)
        })
    }, [refresh])

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const approveReport = (index, currentTableData) => {

        console.log(currentTableData)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(transposeArray(currentTableData)[index][5])
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify([transposeArray(currentTableData)[index][5]]) //Has to be surrounded by array tag as chase copies this from other endpoints despite it only being 1 report.
        };

        props.tokenSafeAPIRequest('/report/reserve_release/approve', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Approved Reports Successfully at: ' + getCurrentTimeFormatted())
                setRefresh((refresh) => !refresh)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const denyReport = (index, currentTableData) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(transposeArray(currentTableData)[index][5])
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            IDs: [transposeArray(currentTableData)[index][5]], //Has to be surrounded by array tag as chase copies this from other endpoints despite it only being 1 report.
            rejection_reason: '    ',
            })
        };

        props.tokenSafeAPIRequest('/report/reserve_release/reject', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Denied Reports Successfully at: ' + getCurrentTimeFormatted())
                setRefresh((refresh) => !refresh)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [notification, setNotification] = useState(false)

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} buttonList={buttonList} title={"Approve Reserve Release Requests"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div></div>
            <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></Table>
            <ModalManager colors={props.colors}>
                <ViewRecipients colors={props.colors} isOpen={recipientModal} setIsOpen={() => setRecipientModal(false)} selectedReport={reports[selectedReport]} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ViewRecipients>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
