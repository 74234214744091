import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const [report, setReport] = useState()

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + props.selectedReport.code + '?fetch_links=true', requestOptions, '', (result, status) => {
            setReport(result)
            console.log(result)
        })
    },[])

    return (
        <div style={{width: '500px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', paddingBottom: '10px'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Recipients</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', paddingTop: '1px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {report?.spiffs?.map((rec) => {
                        return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.dealership_spiff ? rec?.dealership_ID.name:rec?.spiffee_ID.name} - ${rec?.amount}</span>)
                    })}
                    {report?.profit_splits?.map((rec) => {
                        return (<span style={{paddingTop: '5px', fontWeight: '500'}}>{rec?.user_ID.first_name + ' ' + rec?.user_ID.last_name} - ${rec?.net}</span>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default Main
