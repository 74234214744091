import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [recipients, setRecipients] = useState([])

    console.log(props)

    const [shippingTypes, setShippingTypes] = useState([
        { value: 1, label: 'Dealer Address' },
        { value: 2, label: 'Spiffee Address' },
        { value: 3, label: 'Subagent Address' },
    ])

    //these values dont matter for visas bc i did a bandaid fix to determine what the values is for visa via a function when sending the data to the backend submitReport()
    const [spiffTypes, setSpiffTypes] = useState([
        { value: 'Visa', label: '$100 Visa' },
        { value: 'Visa', label: '$50 Visa' },
        { value: 'Visa', label: '$25 Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Check', label: 'Check' },
    ])


    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'Recipient',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={recipients} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<TextInput colors={props.colors} onChange={(e) => onTableChange(e, props.index, 1)} prefix='$' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Shipping Address',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={shippingTypes} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: undefined,
        },
        {
            title: 'Spiff Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={spiffTypes} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: undefined,
        },
        {
            title: 'Remove',
            displayElement: (props) => (<IconButton image={Delete} onClick={() => deleteRow(props.index, props.tableIDs)} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: undefined,
        },
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])

    const findAccountDealers = () => {
        let tempID = ''
        props?.accounts?.map((account) => {
            if (account.id === props.selectedAccount)
            {
                tempID = account.dealership_owner_IDs;
            }
        })
        return tempID;
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        props.tokenSafeAPIRequest('/spiffee/all_names', requestOptions, '', (result) => {
            console.log(result)
            let tempRecipient = result.map((item) => ({
                label: item.name,
                value: item.id,
            }))
            findAccountDealers(props?.selectedAccount, props?.accounts)?.map((dealer) => {
                console.log(dealer)
                tempRecipient.push({label: dealer.name, value: '$' + dealer.id}) //$ is used to determine if the id is a dealer & it should be payed to the dealer
                tempRecipient.push({label: 'Subagents under ' + dealer.name, value: '!' + dealer.id}) //! is used to determine if the id is a dealer & its should be the subagents under a dealership
            })
            console.log(tempRecipient)
            setSpliteeTableTitles([
                {
                    title: 'Recipient',
                    displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={tempRecipient} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                    default: '',
                },
                {
                    title: 'Amount',
                    displayElement: (props) => (<TextInput colors={props.colors} onChange={(e) => onTableChange(e, props.index, 1)} prefix='$' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
                    default: '',
                },
                {
                    title: 'Shipping Address',
                    displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={shippingTypes} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                    default: undefined,
                },
                {
                    title: 'Spiff Type',
                    displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={spiffTypes} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                    default: undefined,
                },
                {
                    title: 'Remove',
                    displayElement: (props) => (<IconButton image={Delete} onClick={() => deleteRow(props.index, props.tableIDs)} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
                    default: undefined,
                },
            ])
        })
    },[])

    const [deletedTypes, setDeletedTypes] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        props.setTableValues((spliteeTableValues2) => spliteeTableValues2.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        props.setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const deleteRow = (newIndex, currentTableData) => {

        console.log(newIndex, currentTableData)

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedTypes((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        props.setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        props.setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        if (x === 0)
        {
            onTableChange(4, )
        }
        props.setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        props.setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Recipients</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={props.tableValues} tableInfo={spliteeTableTitles} tableIDs={props.tableData} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false} key="Modal"></Table>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button colors={props.colors} onClick={() => addRow()} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                    <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add New Recipient</span>
                </div>
                <Button colors={props.colors} onClick={() => {props.saveRecipients(); props.setIsOpen()}} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
